@import '../../colors';

.h2-primary-text {
  font-size: 2.5rem;

  &-detail {
    color: $primary-color;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.h2-primary-text,
.h3-secondary-text {
  color: $primary-color;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;

  &::after {
    content: '';
    display: block;
    width: 11.5rem;
    height: .4rem;
    margin: 0 auto;
    margin-top: 1rem;
    background-color: $secondary-color;
    border-radius: 1.2rem;
  }
}

.h3-secondary-text,
.h3-tertiary-text {
  font-size: 2rem;
}

.about-container {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
}

.paragraph-container {
  width: 70%;
  margin-left: 5rem;

  & p {
    font-size: 1.8rem;
    font-weight: 400;
    color: $grey-color;
    line-height: 4.5rem;

    &:not(:last-of-type) {
      margin-bottom: 2.5rem;
    }
  }
}