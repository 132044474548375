@import '../colors';

.ProjectCarousel {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  & .slides {
    overflow: hidden;
    max-height: 35rem;
    border-radius: 2rem;
    position: relative;

    &__toggles {
      width: 25%;
      position: absolute;
      bottom: 8%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    &__toggle {
      width: 2rem;
      height: 2rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border: 2px solid $secondary-color;
      border-radius: 50%;

      &--inner {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: transparent;
        pointer-events: none;
        -webkit-transition: background-color .2s;
        -o-transition: background-color .2s;
        transition: background-color .2s;
      }

      &:hover,
      &.active {
        cursor: pointer;

        .slides__toggle--inner {
          background: $secondary-color;
        }
      }
    }
  }

  & .navigation-slide {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  & .buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}