.services-container {
  margin-top: 11.5rem;
  display: flex;
  justify-content: space-between;
}

.service {
  width: 33.333%;
  border-radius: 2rem;
  text-align: center;
  padding: 3rem 4rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, .15);

  & .h3-tertiary-text {
    margin-top: 2.5rem;
    margin-bottom: 4rem;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;

    &:nth-child(3) {
      margin-bottom: 3rem;
    }
  }

  & p {
    font-size: 1.6rem;
    line-height: 4rem;
  }

  .second{ margin-top: 1.5rem;}

  &:not(:last-of-type) {
    margin-right: 2%;
  }

  & svg {
    font-size: 5rem;
  }

  &--1 {
    background: linear-gradient(to right bottom, #696DF4, #3C40C6);
  }

  &--2 {
    background: linear-gradient(to right bottom, #FECA57, #FF9D00);
  }

  &--3 {
    background: linear-gradient(to right bottom, #FF5522, #FF251C);
  }
}