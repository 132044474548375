@import '../colors';

.navigation--links {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  & a {
    display: inline-block;
    font-weight: 600;
    font-size: 2rem;
    color: $primary-color;
    margin-left: 5rem;
    text-align: center;

    &:hover,
    &:active {
      &::after {
        opacity: 1;
      }
    }

    &::after {
      display: block;
      content: '';
      width: 5rem;
      height: 0.4rem;
      background-color: $secondary-color;
      border-radius: 1.2rem;
      margin: 0 auto;
      margin-top: 0.5rem;
      opacity: 0;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    }
  }
}