@import '../colors';

.projects {
  width: 30%;
  height: 40rem;
  margin-bottom: 3rem;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 150rem;
  perspective: 150rem;
  position: relative;

  &__side {
    width: 100%;
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
    border-radius: 2rem;
    position: absolute;
    height: 100%;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;

    &--front {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
      overflow: hidden;

      & img {
        width: 100%;
        height: 100%;
      }
    }

    &--back {
      padding: 6rem 4rem;
      background: #454791;
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      pointer-events: none;
    }
  }

  &:hover .projects__side {
    &--front {
      -webkit-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
    }

    &--back {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
      pointer-events: all;
    }
  }

  &:not(:nth-child(3n+3)) {
    margin-right: 6rem;
  }

  &__btn:link,
  &__btn:visited {
    display: block;
    text-transform: capitalize;
    text-decoration: none;
    padding: 2rem 4rem;
    border-radius: 1.5rem;
    font-size: 1.7rem;
    font-weight: 600;
    text-align: center;
    color: #fff;
  }

  &--yellow {
    background-color: $secondary-color;
  }

  &--purple {
    background-color: $primary-color;
  }

  &--red {
    background-color: $tertiary-color;
  }
}