@import '../colors';

.hero-area__navigation--sm {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 70;
  opacity: 0;
  width: 0%;
  -webkit-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}

.navigation {
  display: none;
  position: relative;

  &__hamburger {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 3rem;
    height: 5px;
    background-color: $primary-color;
    border-radius: 2rem;

    &::before,
    &::after {
      width: 3rem;
      content: '';
      position: absolute;
      height: 5px;
      background: $primary-color;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      border-radius: 2rem;
    }

    &::before {
      top: -1rem;
    }

    &::after {
      top: 1rem;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    position: relative;
    cursor: pointer;
    background: transparent;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;

    &-detail {
      background: transparent;
      width: 7rem;
      height: 7rem;
      border-radius: 50%;
      position: absolute;
      top: 0rem;
      right: 3rem;
      z-index: 100;
    }
  }

  &__background {
    -webkit-transition: all 0.8s ease-out;
    -o-transition: all 0.8s ease-out;
    transition: all 0.8s ease-out;
    width: 6rem;
    height: 6rem;
    position: absolute;
    top: 3rem;
    right: 3rem;
    border-radius: 50%;
    background: -o-radial-gradient(#5c60d6, $primary-color);
    background: radial-gradient(#5c60d6, $primary-color);
    z-index: 60;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  &__checkbox {
    display: none;
  }

  &__list {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    width: 0;
    height: 0;
  }

  &__item {
    & a {
      width: 100%;
      text-decoration: none;
      font-size: 50px;
      color: white;
      font-weight: 600;
      letter-spacing: 1.5px;

      &:hover::after,
      &:active::after {
        opacity: 1;
      }

      &::after {
        display: block;
        content: '';
        width: 10rem;
        height: 0.4rem;
        background-color: var(--secondary-color);
        border-radius: 1.2rem;
        margin: 0 auto;
        margin-top: 0.5rem;
        opacity: 0;
        -webkit-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
      }
    }

    &:not(:last-child) {
      margin-bottom: 5rem;
    }
  }

  &__checkbox:checked ~ &__background {
    -webkit-transform: scale(100);
    -ms-transform: scale(100);
    transform: scale(100);
  }

  &__checkbox:checked ~ &__button {
    background: #fff;
    position: fixed;
    right: 3rem;
    & .navigation__hamburger {
      background: rgba(0, 0, 0, 0);

      &::before {
        top: 0;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &::after {
        top: 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  &__checkbox:checked ~ .hero-area__navigation--sm {
    opacity: 1;
    width: 100%;

    & .navigation__list {
      width: 100%;
      height: 100vh;
      margin-top: 20vh;
    }
  }

  &__icons {
    width: 80%;
    text-align: right;
    position: absolute;
    bottom: 5%;
    right: 10%;

    & a {
      &:link,
      &:visited {
        margin-left: 10%;
        color: #fff;
      }

      & svg {
        font-size: 2.5rem;
      }
    }
  }
}
