@import '../../colors';

.detail {
  width: 100vw;
  height: max-content;
  background: $primary-color;
  overflow: hidden;
}

.description {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;

  &-text {
    margin-right: 5rem;
    flex-basis: 50%
  }

  & h1 {
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 11.5rem;
      height: .4rem;
      margin-top: 1rem;
      background-color: $secondary-color;
      border-radius: 1.2rem;
    }
  }

  & p {
    font-size: 1.65rem;
    line-height: 1.9
  }

  .skills__icons {
    width: 100%;
    justify-content: flex-start;
    margin-top: 0;

    & .icon {
      margin-right: 1rem;
      background: none;
    }
  }

  .navigation__hamburger,
  .navigation__hamburger::after,
  .navigation__hamburger::before {
    background-color: #fff;
  }
}

.button,
.button:visited,
.button:active {
  font-weight: 600;
  padding: 1.5rem 4rem;
  border-radius: .5rem;
  font-size: 1.5rem;
}

.buttons {
  margin-top: 6rem;
}

.button-yellow {
  background: $secondary-color;
  color: $primary-color;
}

.button-red {
  background: $tertiary-color;
  color: #fff;
}