*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: Montserrat, sans-serif;
  color: white;
  scroll-behavior: smooth;
}

body {
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

a {
  text-transform: capitalize;
  text-decoration: none;
}

header,
section,
footer {
  padding: 4rem;
  height: 100vh;
  position: relative;
}

section.portfolio {
  height: auto;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

.container-detail {
  max-width: 120rem;
  margin: 0 auto;
  position: relative;
}

.h1-primary-text {
  font-size: 5rem;
  color: $grey-color;
  text-shadow: 0 0 .6rem rgba(0, 0, 0, .15);
  font-weight: 600;
  margin-bottom: 2.5rem;
}

.h1-secondary-text {
  font-size: 5.5rem;
  color: $grey-color;
  font-family: Lato, sans-serif;
  font-weight: 100;
  margin-bottom: 2.5rem;
  text-shadow: 0 .3rem .6rem rgba(0, 0, 0, .15);
}

.h3-primary-text {
  font-size: 2.5rem;
  color: #444;
  font-weight: 500;
  margin-bottom: 5rem;
}

.white {
  color: #fff;
}

.yellow {
  color: $secondary-color;
}

.purple {
  color: $primary-color;
}

.icon-red {
  background-color: $tertiary-color;
}

.icon-purple {
  background-color: $primary-color;
}

.hide {
  display: none;
}