@keyframes pulsateY {
  from {
    transform: translateY(0rem);
    -webkit-transform: translateY(0rem);
    -moz-transform: translateY(0rem);
    -ms-transform: translateY(0rem);
    -o-transform: translateY(0rem);
}

  to {
    transform: translateY(1rem);
    -webkit-transform: translateY(1rem);
    -moz-transform: translateY(1rem);
    -ms-transform: translateY(1rem);
    -o-transform: translateY(1rem);
}
}

@keyframes pulsateYrev {
  from {
    transform: translateY(0rem);
    -webkit-transform: translateY(0rem);
    -moz-transform: translateY(0rem);
    -ms-transform: translateY(0rem);
    -o-transform: translateY(0rem);
}

  to {
    transform: translateY(-1rem)  ;
    -webkit-transform: translateY(-1rem);
    -moz-transform: translateY(-1rem);
    -ms-transform: translateY(-1rem);
    -o-transform: translateY(-1rem);
}
}

@keyframes moveRight {
  0% {
    opacity: 0;
    transform: translateX(-3rem);
    -webkit-transform: translateX(-3rem);
    -moz-transform: translateX(-3rem);
    -ms-transform: translateX(-3rem);
    -o-transform: translateX(-3rem);
}

  70% {
    opacity: 1;
    transform: translateX(1rem);
    -webkit-transform: translateX(1rem);
    -moz-transform: translateX(1rem);
    -ms-transform: translateX(1rem);
    -o-transform: translateX(1rem);
}

  100% {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
}
}

@keyframes moveLeft {
  0% {
    opacity: 0;
    transform: translateX(3rem);
    -webkit-transform: translateX(3rem);
    -moz-transform: translateX(3rem);
    -ms-transform: translateX(3rem);
    -o-transform: translateX(3rem);
}

  70% {
    opacity: 1;
    transform: translateX(-1rem);
    -webkit-transform: translateX(-1rem);
    -moz-transform: translateX(-1rem);
    -ms-transform: translateX(-1rem);
    -o-transform: translateX(-1rem);
}

  100% {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
}
}

@keyframes blink {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes pulsateXleftToRight {
  from {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
}

  to {
    transform: translateX(-1rem);
    -webkit-transform: translateX(-1rem);
    -moz-transform: translateX(-1rem);
    -ms-transform: translateX(-1rem);
    -o-transform: translateX(-1rem);
}
}

@keyframes pulsateXrightToLeft {
  from {
    transform: translateX(0rem);
    -webkit-transform: translateX(0rem);
    -moz-transform: translateX(0rem);
    -ms-transform: translateX(0rem);
    -o-transform: translateX(0rem);
}

  to {
    transform: translateX(1rem);
    -webkit-transform: translateX(1rem);
    -moz-transform: translateX(1rem);
    -ms-transform: translateX(1rem);
    -o-transform: translateX(1rem);
}
}