.photocircle {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 15rem;
  width: 15rem;
  border: none;
  border-radius: 100%;
  overflow: hidden;
  float: left;
  shape-outside: circle();
  margin: 1.5rem;

  & img {
    width: 100%;
    height: 100%;
  }
}