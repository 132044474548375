@import '../colors';

.photocard {
  height: 45rem;
  width: 30%;
  position: relative;
  -webkit-transform: translateX(-5rem);
  -ms-transform: translateX(-5rem);
  transform: translateX(-5rem);

  & div,
  & .photo-space {
    height: 100%;
    width: 100%;
  }

  & .yellow-rectangle {
    border: 1.5rem solid $secondary-color;
  }

  .photo-space {
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: -3rem;
    right: -3rem;
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, .25);
    box-shadow: 0 0 1rem rgba(0, 0, 0, .25);
  }

  & img {
    width: 80%;
    height: 60%;
  }
}