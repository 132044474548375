.polytl {
  position: absolute;
  top: 0;
  z-index: -1;
  left: 0;
  height: 100%;
  width: 100%;
  background: $primary-color;
}

.polytr {
  position: absolute;
  top: 0;
  z-index: -1;
  right: 0;
  height: 100%;
  width: 100%;
  background: $primary-color;
}

.polybr {
  position: absolute;
  bottom: 0;
  z-index: 1;
  right: 0;
  height: 100%;
  width: 100%;
  background: $primary-color;

}

.polybl {
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0;
  height: 100%;
  width: 100%;
  background: $primary-color;
}

.bgservice {
  -webkit-clip-path: polygon(0 0, 0 20%, 5% 0);
  clip-path: polygon(0 0, 0 20%, 5% 0);
}

.bgservice2 {
  -webkit-clip-path: polygon(90% 0, 100% 0, 100% 100%, 70% 100%);
  clip-path: polygon(90% 0, 100% 0, 100% 100%, 70% 100%);

}

.bgabbot {
  -webkit-clip-path: polygon(100% 50%, 90% 100%, 100% 100%);
  clip-path: polygon(100% 50%, 90% 100%, 100% 100%);
}

.bghero {
  -webkit-clip-path: polygon(0 0, 55% 0, 30% 100%, 0 100%);
  clip-path: polygon(0 0, 55% 0, 30% 100%, 0 100%);
}

.bgab {
  -webkit-clip-path: polygon(0 0, 0 120%, 30% 0);
  clip-path: polygon(0 0, 0 120%, 30% 0);
}

.bgport {
  -webkit-clip-path: polygon(70% 0, 100% 0, 100% 100%, 42% 100%);
  clip-path: polygon(70% 0, 100% 0, 100% 100%, 50% 100%);
}

.circles {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background: $secondary-color;
  border-radius: 100%;
  z-index: 1;
  position: absolute;
  -webkit-animation: blink 2s infinite alternate ease-out;
          animation: blink 2s infinite alternate ease-out;
}

.ycircle {
  width: 5.5rem;
  height: 5.5rem;
  background-color: $secondary-color;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  & svg {
    color: #fff;
    font-size: 2.3rem;
  }
}

.line {
  border-left: 3px solid $secondary-color;
  height: 4rem;
  border-radius: 1rem;
}

.cbr {
  -webkit-animation: pulsateXrightToLeft 1s infinite alternate ease-in;
          animation: pulsateXrightToLeft 1s infinite alternate ease-in;
  z-index: 1;
  -webkit-clip-path: circle(8.5% at 100% 100%);
  clip-path: circle(8.5% at 100% 100%);
  background-color: $secondary-color;
}

.cbl {
  -webkit-animation: pulsateXleftToRight 1s infinite alternate ease-in;
          animation: pulsateXleftToRight 1s infinite alternate ease-in;
  z-index: 1;
  -webkit-clip-path: circle(8.5% at 0 100%);
  clip-path: circle(8.5% at 0 100%);
  background-color: $secondary-color;
}

.ctr {
  -webkit-animation: pulsateXrightToLeft 1s infinite alternate ease-in;
          animation: pulsateXrightToLeft 1s infinite alternate ease-in;
  z-index: 1;
  -webkit-clip-path: circle(8.5% at 100% 0);
  clip-path: circle(8.5% at 100% 0);
  background-color: $secondary-color;
}

.c1 {
  top: 20%;
  left: 7%;
}

.c2 {
  top: 10%;
  left: 40%;
}

.c3 {
  bottom: 10%;
  left: 20%;
}

.c4 {
  bottom: 20%;
  left: 3%;
}

.c5 {
  top: 5%;
  left: 10%;
}

.c6 {
  top: 80%;
  left: 3%;
}

.c7 {
  bottom: 10%;
  right: 3%;
}

.c8 {
  top: 2%;
  right: 3%;
}

.c9 {
  top: 45%;
  right: 10%;
}

.c10 {
  bottom: 5%;
  right: 20%;
}