@import '../../colors';

.hero-area {
  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__hero-text {
    margin-top: 15%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &--details {
      margin-top: 1.5rem;
      text-align: left;
      animation: moveLeft 1s 1 ease-out;
    }
  }

  &__coding-image {
    width: 60%;
    animation: moveRight 1s 1 ease-out;
  }

  &__contact-me,
  &__resume {
    display: inline-block;
    padding: 2rem 3rem;
    font-weight: 400;
    font-size: 1.75rem;
    border-radius: 3.5rem;
    transition: all 0.3s;

    &:hover {
      box-shadow: 0 .3rem 1.5rem rgba(0, 0, 0, .5);
      transform: translateY(-5px);
    }

    &:active {
      box-shadow: 0 .3rem .5rem rgba(0, 0, 0, .3);
      transform: translateY(-3px);
    }
  }

  &__contact-me {
    justify-content: space-between;
    background: linear-gradient(to bottom right, #6A6EFF, $primary-color);
    color: #fff;

    & img {
      margin-left: 2rem;
    }
  }

  &__resume {
    background-color: #D5D6FF;
    margin-left: 2.5rem;
    color: $primary-color
  }

  &__next {
    position: absolute;
    left: 48.5%;
    bottom: 3.5rem;
    animation: pulsateY .4s infinite alternate;
    animation-delay: 0.8s;
  }

  &__icons {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 10%;
    bottom: 0;

    & svg {
      display: inline-block;
      font-size: 2.5rem;
      color: $grey-color;
      margin-bottom: 2.5rem;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.5);
        color: $primary-color;
      }
    }
  }
}