/*MEDIA QUERIES*/

/*
0-600 phone 37.5em
600-900 tablet portrait 56.25em
900-1250 tablet landscape 78.125em
[1250-1800] Oriinal code
1800+ Big desktops 112.5em
*/

/*HEIGHT RELATED QUERIES*/
@media (max-height: 59.375em) {
  .hero-area__hero-text--details {
    margin-top: 5rem;
  }
}

@media (max-height: 53.75em) {
  .hero-area__hero-text {
    margin-top: 9%;
  }

  .skills {
    margin-top: 4rem;

    &__icons {
      width: 65%;

      & .icon {
        width: 8rem;
        height: 8rem;

        & svg {
          font-size: 3rem;
        }
      }
    }
  }
}

@media (max-height: 51.875em) {
  header .container {
    height: 100%;
  }

  .hero-area__icons {
    right: 10%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    & a {
      margin-left: 2rem;
    }
  }

  .line {
    display: none;
  }
}

@media (max-height: 50em) {
  .about,
  .hero-area {
    &__next {
      position: static;
      margin: 8% auto;
    }
  }
}

@media (max-height: 41.06em) {
  footer {
    height: 100%;
  }
}

/*WIDTH RELATED QUERIES*/
@media (max-width: 78.125em) {
  html {
    font-size: 56.25%;
  }

  .hero-area__hero-text {
    & .hero-area__coding-image {
      width: 55%;
    }

    &--details {
      margin-top: 0;
    }
  }

  .h3-primary-text {
    margin-bottom: 4.5rem;
  }

  .photocard {
    height: 40rem;
    width: 25%;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .paragraph-container {
    margin-left: 0;
    width: 65%;

    & p {
      line-height: 4.25rem;
    }
  }

  .skills__icons {
    width: 65%;

    & .icon {
      width: 8rem;
      height: 8rem;

      & svg {
        font-size: 3rem;
      }
    }
  }

  .c9 {
    display: none;
  }

  .projects-container .projects {
    width: 22%;
    height: 35rem;
  }
}

@media (max-width: 64em) {
  .photocard {
    height: 35rem;
  }

  .projects-container {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    & .projects__btn {
      &:link,
      &:visited {
        padding: 1.5rem;
        border-radius: 1rem;
        font-size: 1.5rem;
      }
    }
  }
}

@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hide {
    display: block;
  }

  .hero-area__navigation {
    display: none;
  }

  .hero-area__hero-text {
    margin-top: 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    & .hero-area__coding-image {
      width: 60%;
    }

    &--details {
      text-align: right;
      margin-top: 5%;
    }
  }

  .h1-secondary-text {
    font-size: 4rem;
  }

  .about-container {
    display: block;
    width: 70%;
    margin: 0 auto;
    margin-top: 6rem;
  }

  .photocard {
    display: none;
  }

  .photocircle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: translateX(-2rem);
    -ms-transform: translateX(-2rem);
    transform: translateX(-2rem);
  }

  .paragraph-container {
    margin-top: 3rem;
    width: 100%;
    text-align: left;
    margin-left: 5rem;
  }

  .skills__icons {
    width: 75%;
  }

  .skills {
    margin-top: 6rem;
  }

  .services-container {
    margin-top: 3rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .services,
  .portfolio {
    height: 100%;
  }

  .service {
    padding: 3rem;

    & .service--2 {
      margin-right: 0;
    }

    &--1,
    &--2 {
      margin-bottom: 2%;
    }
  }

  .projects-container {
    margin-top: 5rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    & .projects {
      width: 40%;
      height: 50rem;

      &:nth-child(even) {
        margin-right: 0;
      }

      &:nth-child(odd) {
        margin-right: 3%;
      }

      & .projects__side--back {
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
      }
    }
  }

  .detail {
    overflow-y: scroll;
    height: 100%;
  }

  .description h1::after {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .sliders {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .description {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;

    &-text {
      margin-right: 0 !important;

      & .text-2 {
        margin-top: 7rem;
      }
    }

    & p {
      padding: 0 7rem;

      &:first-of-type {
        margin-top: 4rem;
      }
    }

    & .skills__icons {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  .description .buttons {
    margin-top: 0;
    margin: 6rem 0;
  }
}

@media (max-width: 37.5em) {
  html {
    font-size: 43.75%;
  }

  .ProjectCarousel {
    margin-top: 10rem;
  }

  .circles,
  .cbr,
  .line,
  .bgab,
  .bgservice,
  .bgport {
    display: none !important;
  }

  .bgservice2 {
    -webkit-clip-path: polygon(80% 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(80% 0, 100% 0, 100% 100%, 0 100%);
  }

  .bghero {
    -webkit-clip-path: polygon(0 0, 0 60%, 53% 0);
    clip-path: polygon(0 0, 0 60%, 53% 0);
  }

  .bgabbot {
    -webkit-clip-path: polygon(100% 100%, 80% 100%, 100% 70%);
    clip-path: polygon(100% 100%, 80% 100%, 100% 70%);
  }

  .hero-area {
    &__hero-text {
      margin-top: 5%;

      & .hero-area__coding-image {
        width: 95%;
      }

      &--details {
        text-align: center;
        margin-top: 7%;
      }
    }

    &__contact-me {
      width: 80%;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      padding: 2.15rem 5rem;
      font-size: 2.5rem;
    }

    &__resume {
      width: 80%;
      margin-left: 0;
      margin-top: 3.57rem;
      padding: 2.15rem 5rem;
      font-size: 2.5rem;
    }

    &__icons {
      display: none;
    }
  }

  .h1-primary-text {
    font-size: 5.7rem;
  }

  .h1-secondary-text {
    font-size: 5rem;
  }

  .about-container {
    width: 100%;
    margin-top: 4.28rem;
  }

  .paragraph-container {
    margin: 0;
    margin-top: 4.28rem;

    & p {
      font-size: 1.43rem;
      text-align: center;
      line-height: 2.85rem;
      margin-bottom: 2.142rem;
    }
  }

  .photocircle {
    float: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    margin: 0 auto;
  }

  .h2-primary-text,
  .skills {
    margin-top: 3.5rem;

    &__icons {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      & .icon {
        margin-left: 1.5rem;
        margin-bottom: 1rem;
        width: 6.5rem;
        height: 6.5rem;

        & svg {
          font-size: 3rem;
        }
      }
    }
  }

  .h3-primary-text {
    font-size: 1.85rem;
  }

  .service {
    width: 80%;
    margin: 0;
    margin-bottom: 5%;

    & .h3-tertiary-text {
      margin-bottom: 3rem;
    }

    &--1 {
      margin-right: 0;
    }
  }

  .projects {
    width: 80% !important;
    margin-right: 0 !important;
    margin-bottom: 5% !important;
  }

  .contact__form {
    width: 80%;

    & svgnput[type='submit'] {
      width: 80%;
      margin-top: 0;
    }

    & svgnput:not([type='submit']),
    & textarea {
      padding: 2rem;
      font-size: 1.6rem !important;
    }

    & .ycircle {
      width: 4.5rem;
      height: 4.5rem;

      & svg {
        font-size: 2rem;
      }
    }
  }

  .contact__message {
    font-size: 1.25rem;
    padding: 1rem;
  }

  .description p {
    padding: 0 5rem;
  }
}

@media (max-width: 26.56em) {
  .skills_icons {
    width: 100%;

    & .icon {
      width: 5rem;
      height: 5rem;

      & svg {
        font-size: 2rem;
      }
    }
  }

  .hero-area {
    &__hero-text {
      & .hero-area__coding-image {
        width: 80%;
      }
    }

    &__contact-me,
    &__resume {
      width: 70%;
      font-size: 2rem;
      padding: 2rem 3rem;
    }
  }

  .h1-primary-text {
    font-size: 4rem;
    margin-bottom: 1.5rem;
  }

  .h1-secondary-text {
    font-size: 3rem;
  }

  .description p {
    padding: 0;
  }
}
