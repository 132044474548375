@import '../../colors';

.contact {
  background-color: $primary-color;
  text-align: center;
  position: relative;

  & h2 {
    text-transform: capitalize;
    color: white;

    &::after {
      margin-top: 2rem;
    }
  }

  &__copy {
    display: block;
    color: #7D7FC1;
    font-size: 1.2rem;
    bottom: 5rem;
    margin: 10% auto 0 auto;
  }

  &__top {
    position: absolute;
    right: 10%;
    bottom: 5%;
    animation: pulsateYrev .4s infinite alternate;
    animation-delay: 0.8s;
  }

  &__form {
    width: 80%;
    margin: 0 auto;

    & input[type='text'] {
      margin-top: 5%;
    }

    & input,
    & textarea {
      border: none;
      font-size: 2rem !important;
      font-weight: bold;
      font-family: Montserrat, sans-serif;
      color: $primary-color;

      &:focus {
        outline: none;
      }
    }

    & input:not([type='submit']),
    & textarea {
      width: 100%;
      display: block;
      margin-bottom: 5rem;
      padding: 2rem 3rem;
      border-radius: 1rem;

      &::placeholder {
        color: $primary-color;
        font-weight: normal;
      }
    }

    & input[type='submit'] {
      margin-top: 4rem;
      padding: 2rem 5.5rem;
      border: none;
      background-color: $secondary-color;
      font-weight: 600;
      border-radius: 4rem;
      text-align: center;
      transition: all .3s;

      &:hover {
        cursor: pointer;
        box-shadow: 0 .3rem 1.5rem rgba(0, 0, 0, .5);
        transform: translateY(-5px);
      }

      &:active {
        box-shadow: 0 .3rem .5rem rgba(0, 0, 0, .3);
        transform: translateY(-3px);
      }
    }

    & textarea {
      resize: none;
      height: 17rem;
    }
  }
}